import React, { useEffect, useState } from 'react';
import './DoorDash.css';
import flower from './icons/flower.png';
import greenOnion from './icons/green_onion.png';

const DoorDash = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const flowerStyle = {
    transform: `translateY(${scrollPosition * -0.1}px)`,
    transition: 'transform 0.5s ease-out',
  };

  const greenOnionStyle = {
    transform: `translateY(${scrollPosition * -0.1}px)`,
    transition: 'transform 0.5s ease-out',
  };

  return (
    <div id="order" className="doordash-section">
      <h2>Order Online</h2>
      <p>For Pickup or Delivery</p>
      
      <div className="button-container">
      <a href="https://order.online/business/dumpling-house-13091475" className="doordash-link">
          <button className="doordash-button">Order via DoorDash</button>
      </a>
      <a href="https://direct.chownow.com/order/40786/locations/61860" className="chownow-link">
          <button className="chownow-button">Order via ChowNow</button>
      </a>

      </div>

      <img
        src={flower}
        alt="Flower decoration"
        className="flower-illustration"
        style={flowerStyle}
      />

      <img
        src={greenOnion}
        alt="Green onion decoration"
        className="green-onion-illustration"
        style={greenOnionStyle}
      />
    </div>
  );
};

export default DoorDash;